/* eslint-disable import/no-anonymous-default-export */
export const nameMap = {
  'Liverpool FC': {
    scott: 1,
    david: 5,
    quike: 3,
    elliot: 3,
    emmett: 4,
  },
  'Everton FC': {
    scott: 8,
    david: 6,
    quike: 9,
    elliot: 9,
    emmett: 9,
  },
  'Wolverhampton Wanderers FC': {
    scott: 13,
    david: 10,
    quike: 11,
    elliot: 11,
    emmett: 12,
  },
  'Chelsea FC': {
    scott: 3,
    david: 4,
    quike: 4,
    elliot: 1,
    emmett: 3,
  },
  'Aston Villa FC': {
    scott: 11,
    david: 17,
    quike: 10,
    elliot: 13,
    emmett: 11,
  },
  'Leicester City FC': {
    scott: 4,
    david: 7,
    quike: 6,
    elliot: 5,
    emmett: 5,
  },
  'Tottenham Hotspur FC': {
    scott: 6,
    david: 16,
    quike: 5,
    elliot: 6,
    emmett: 6,
  },
  'Manchester City FC': {
    scott: 2,
    david: 2,
    quike: 1,
    elliot: 2,
    emmett: 2,
  },
  'Leeds United FC': {
    scott: 10,
    david: 8,
    quike: 12,
    elliot: 10,
    emmett: 10,
  },
  'Southampton FC': {
    scott: 15,
    david: 11,
    quike: 14,
    elliot: 15,
    emmett: 14,
  },
  'Crystal Palace FC': {
    scott: 18,
    david: 12,
    quike: 17,
    elliot: 12,
    emmett: 13,
  },
  'Arsenal FC': {
    scott: 7,
    david: 1,
    quike: 7,
    elliot: 8,
    emmett: 7,
  },
  'West Ham United FC': {
    scott: 12,
    david: 9,
    quike: 8,
    elliot: 7,
    emmett: 8,
  },
  'Newcastle United FC': {
    scott: 16,
    david: 13,
    quike: 13,
    elliot: 16,
    emmett: 15,
  },
  'Manchester United FC': {
    scott: 5,
    david: 3,
    quike: 2,
    elliot: 4,
    emmett: 1,
  },
  'Brighton & Hove Albion FC': {
    scott: 9,
    david: 15,
    quike: 16,
    elliot: 18,
    emmett: 17,
  },
  'Burnley FC': {
    scott: 14,
    david: 14,
    quike: 15,
    elliot: 14,
    emmett: 19,
  },
  'Brentford FC': {
    scott: 17,
    david: 20,
    quike: 20,
    elliot: 20,
    emmett: 18,
  },
  'Watford FC': {
    scott: 20,
    david: 18,
    quike: 18,
    elliot: 19,
    emmett: 20,
  },
  'Norwich City FC': {
    scott: 19,
    david: 19,
    quike: 19,
    elliot: 17,
    emmett: 16,
  }
}

const scott = [
  'Liverpool',
  'City',
  'Chelsea',
  'Leicester',
  'United', // 5
  'Spurs',
  'Arsenal',
  'Everton',
  'Brighton',
  'Leeds', // 10
  'Villa', 
  'Hammers',
  'Wolves',
  'Burnley',
  'Soton', // 15
  'Newcastle',
  'Brentford',
  'Crystal Palace',
  'Norwich',
  'Watford',
];

const david = [
  'Arsenal',
  'City',
  'United',
  'Chelsea',
  'Liverpool', // 5
  'Everton',
  'Leicester City',
  'Leeds',
  'West Ham',
  'Wolves', // 10
  'Southampton',
  'Crystal Palace',
  'Newcastle',
  'Burnley',
  'Brighton', // 15
  'Tottenham',
  'Aston Villa',
  'Watford',
  'Norwich City',
  'Brentford',
];

const quike = [
  'Manchester City',
  'Manchester United',
  'Liverpool',
  'Chelsea',
  'Tottenham', // 5
  'Leicester',
  'Arsenal',
  'West Ham',
  'Everton',
  'Aston Villa', // 10
  'Wolves',
  'Leeds',
  'Newcastle',
  'Southampton',
  'Burnley', // 15
  'Brighton',
  'Crystal Palace',
  'Watford',
  'Norwich',
  'Brentford',
];

const elliot = [
  'Chelsea',
  'Man Shity',
  'Pool',
  'Man Poo',
  'Lichesthair', // 5
  'Spurrrs',
  'West Hamster',
  'Gooners',
  'Toffee',
  'Hot Leads', // 10
  'Where Woves',
  'Crystal meth',
  'Villains',
  'Burnsy',
  'South of Hampton', // 15
  'Oldcastle',
  'Norbitch',
  'Brighten old Alvin',
  'Whatford',
  'Brentford',
];

const emmett = [
  'United',
  'City',
  'Chelsea',
  'Liverpool',
  'Leicester', // 5
  'Tottenham',
  'Arsenal',
  'West Ham',
  'Everton',
  'Leeds', // 10
  'Aston Villa',
  'Wolves',
  'Crystal Palace',
  'Southampton',
  'New Castle', // 15
  'Norwich',
  'Brighton',
  'Brentford',
  'Burnley',
  'Watford',
];

export default {
  scott,
  david,
  quike,
  elliot,
  emmett,
}