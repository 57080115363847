import React, { Fragment } from 'react'
import './App.css';

const Table = ({ table = {} }) => {
  return (
    <div className='table'>
      <h2>
        Live Table
      </h2>
      <div className='grid'>
        {Object.keys(table).map((name) => {
          const position = table[name];
          return (
            <Fragment key={name}>
              <span className='standing'>{position}</span>
              <span className='team'>{name}</span>
            </Fragment>
          )
        })}
      </div>
    </div>
  )
}

export default Table
