import { Fragment, useEffect, useMemo, useState } from 'react';
import { BrowserRouter, NavLink, Redirect, Route } from 'react-router-dom';
import './App.css';
import predictions, { nameMap } from './predictions/predictions21_22';
import Table from './table';

const getModifier = (prediction, actual) => {
  if (prediction === 1 && actual === 1) {
    return -5;
  }
  if (prediction <= 4 && actual <= 4) {
    return -3;
  }
  if (prediction > 17 && actual > 17) {
    return -3;
  }
  return 0;
};

const getEmoji = (playerPoints) => {
  if (!playerPoints) {
    return '';
  }
  if (playerPoints.isPerfect) {
    return 'perfect'
  }
  return playerPoints.isBetterThanGuess ? 'better' : 'worse';
};

const getPointsTotal = (playerPoints) =>
  Object.keys(playerPoints).reduce(
    (total, cur) => total + parseInt(playerPoints[cur].points),
    0
  );

function App() {
  const [currentTable, setCurrentTable] = useState({});
  useEffect(() => {
    fetch(process.env.REACT_APP_LAMBDA)
      .then((res) => res.json())
      .then((table) => {
        const updatedTable = table.reduce(
          (map, { position, team: { name } }) => ({
            ...map,
            [name]: position,
          }),
          {}
        );
        setCurrentTable(updatedTable);
      });
  }, []);

  const points = useMemo(
    () =>
      Object.keys(currentTable).reduce(
        (map, team) => {
          const position = currentTable[team];
          const { scott, david, quike, elliot, emmett } = nameMap[team];
          return {
            scott: {
              ...map.scott,
              [predictions.scott[scott - 1]]: {
                points: Math.abs(position - scott) + getModifier(scott, position),
                isPerfect: Math.abs(position - scott) === 0,
                isBetterThanGuess: position < scott,
              }
            },
            david: {
              ...map.david,
              [predictions.david[david - 1]]: {
                points: Math.abs(position - david) + getModifier(david, position),
                isPerfect: Math.abs(position - david) === 0,
                isBetterThanGuess: position < david,
              }
            },
            quike: {
              ...map.quike,
              [predictions.quike[quike - 1]]: {
                points: Math.abs(position - quike) + getModifier(quike, position),
                isPerfect: Math.abs(position - quike) === 0,
                isBetterThanGuess: position < quike,
              }
            },
            elliot: {
              ...map.elliot,
              [predictions.elliot[elliot - 1]]: {
                points: Math.abs(position - elliot) + getModifier(elliot, position),
                isPerfect: Math.abs(position - elliot) === 0,
                isBetterThanGuess: position < elliot,
              }
            },
            emmett: {
              ...map.emmett,
              [predictions.emmett[emmett - 1]]: {
                points: Math.abs(position - emmett) + getModifier(emmett, position),
                isPerfect: Math.abs(position - emmett) === 0,
                isBetterThanGuess: position < emmett,
              }
            },
          };
        },
        {
          scott: {},
          david: {},
          quike: {},
          elliot: {},
          emmett: {},
        }
      ),
    [currentTable]
  );
  return (
    <div className='App'>
      <header className='App-header'>Table Tracker</header>
      <div className='body'>
        <BrowserRouter>
          <div className='player-tables'>
            <div className='links'>
              <NavLink
                className='link'
                activeClassName='active-link'
                to='/scott'
              >
                Scott <b className='link-points'>{getPointsTotal(points.scott)}</b>
              </NavLink>
              <NavLink
                className='link'
                activeClassName='active-link'
                to='/david'
              >
                David <b className='link-points'>{getPointsTotal(points.david)}</b>
              </NavLink>
              <NavLink
                className='link'
                activeClassName='active-link'
                to='/quike'
              >
                Quike <b className='link-points'>{getPointsTotal(points.quike)}</b>
              </NavLink>
              <NavLink
                className='link'
                activeClassName='active-link'
                to='/elliot'
              >
                Elliot <b className='link-points'>{getPointsTotal(points.elliot)}</b>
              </NavLink>
              <NavLink
                className='link'
                activeClassName='active-link'
                to='/emmett'
              >
                Emmett <b className='link-points'>{getPointsTotal(points.emmett)}</b>
              </NavLink>
            </div>
            <Route
              path='/:name'
              render={({
                match: {
                  params: { name },
                },
              }) => {
                const prediction = predictions[name];
                const playerPoints = points[name];
                return (
                  <div className='picks'>
                    <h3 className='grid-header'>{}</h3>{' '}
                    {/* hacky placeholder for css grid */}
                    <h3 className='grid-header'>Predicted Table</h3>
                    <h3 className='grid-header'>Points</h3>
                    {prediction.map((p, i) => {
                      const predictionHighlighting = getEmoji(playerPoints[p]);
                      return (
                        <Fragment key={p}>
                          <span className='position'>{i + 1}</span>
                          <span className={`team-pick ${predictionHighlighting}`}>{p}</span>
                          <span className='points-away'>{playerPoints[p]?.points}</span>
                        </Fragment>
                      );
                    })}
                    <span>{}</span>
                    {/* hacky placeholder for css grid */}
                    <span className='total-title'>Points Total: </span>
                    <span className='total-points'>
                      {getPointsTotal(playerPoints)}
                    </span>
                  </div>
                );
              }}
            />
            <Redirect from='/' to='/scott' />
          </div>
        </BrowserRouter>
        <Table table={currentTable} />
      </div>
    </div>
  );
}

export default App;
